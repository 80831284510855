// extracted by mini-css-extract-plugin
export var content = "recops-guide-module--content--Y2R5t";
export var description = "recops-guide-module--description--PFidV";
export var form_checkbox = "recops-guide-module--form_checkbox--Hg+eF";
export var form_container = "recops-guide-module--form_container--Es+2S";
export var form_input = "recops-guide-module--form_input--FvI7I";
export var form_input_100w = "recops-guide-module--form_input_100w--evm4L";
export var form_input_40w = "recops-guide-module--form_input_40w--gpAEN";
export var form_input_50w = "recops-guide-module--form_input_50w--Ty0M9";
export var form_input_60w = "recops-guide-module--form_input_60w--rQo5h";
export var form_input_checkbox = "recops-guide-module--form_input_checkbox--EWRVz";
export var form_input_label = "recops-guide-module--form_input_label--ETkDn";
export var form_input_label_required = "recops-guide-module--form_input_label_required--uVd93";
export var form_message = "recops-guide-module--form_message--7m+du";
export var form_submit = "recops-guide-module--form_submit--qP1fW";
export var form_submit_btn = "recops-guide-module--form_submit_btn--HKGQG";
export var form_title = "recops-guide-module--form_title--Xrs8a";
export var image = "recops-guide-module--image--bBSUq";
export var more = "recops-guide-module--more--e1NyP";
export var name_fields = "recops-guide-module--name_fields--ZrJV2";
export var phone_input = "recops-guide-module--phone_input--lDstc";
export var right_section = "recops-guide-module--right_section--EWIsn";
export var subtitle = "recops-guide-module--subtitle--pAE-D";
export var text_area = "recops-guide-module--text_area--6m5UK";
export var title = "recops-guide-module--title--hvUXG";